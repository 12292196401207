const dev = {
  // baseUrl: "https://don-felipe-api-windows.azurewebsites.net",
  baseUrl: 'https://monteverde.metodoc.com.br/api',
  pdfServiceUrl: "https://pdf.metodoc.com",
  azureToken:
    "?sv=2018-03-28&ss=bfqt&srt=sco&sp=rwdlacup&se=2999-01-14T19:19:45Z&st=2019-01-14T11:19:45Z&spr=https,http&sig=%2F3NfuGQmSjuKHgFxXPPfKQ2uAFt8P5pfbzdEh6okHQY%3D",
  googleAnalyticsApiKey: "UA-132355707-1",
  hotjarAppId: 1155379,
  hotjarRevision: 6,
};

const local = {
  ...dev,
  baseUrl: "http://localhost:3001",
  pdfServiceUrl: "http://localhost:5000",
};

const prod = {
  // baseUrl: "https://don-felipe-api-windows.azurewebsites.net",
  baseUrl: 'https://monteverde.metodoc.com.br/api',
  pdfServiceUrl: "https://pdf.metodoc.com",
  azureToken:
    "?sv=2018-03-28&ss=bfqt&srt=sco&sp=rwdlacup&se=2999-01-11T03:42:56Z&st=2019-01-10T19:42:56Z&spr=https,http&sig=K57wu%2BMy2TrhG8UC4L0hbmXV95kwZ7fJOe3nPRWZ5dc%3D",
  googleAnalyticsApiKey: "UA-132355707-1",
  hotjarAppId: 1155379,
  hotjarRevision: 6,
};

const envs = {
  local: local,
  dev: dev,
  prod: prod,
};

const config =
  envs[
    process.env.REACT_APP_STAGE ||
      (window._env_ && window._env_.STAGE) ||
      process.env.npm_config_stage ||
      'dev'
  ]

export default {
  ...config,
  isProduction:
    process.env.REACT_APP_STAGE === "prod" ||
    process.env.npm_config_stage === "prod" ||
    (window._env_ && window._env_.STAGE === "prod"),
  // Add common config values here
};
